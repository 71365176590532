import { lazy } from "react";

// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import AdminLayout from "layout/AdminLayout";
import { ProjectProvider } from "contexts/ProjectContext";
import RoleBasedGuard from "utils/route-guard/RoleGuard";

// views
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const PayGoTerms = Loadable(lazy(() => import("views/pay-go/init")));
const PayGoSetup = Loadable(lazy(() => import("views/pay-go/init/setup")));
const PaymentIntegrations = Loadable(
  lazy(() => import("views/pay-go/init/payment-integrations"))
);
const DevicesPage = Loadable(lazy(() => import("views/pay-go/devices")));
const TransactionsPage = Loadable(
  lazy(() => import("views/pay-go/transactions"))
);
const DashboardPage = Loadable(lazy(() => import("views/admin/dashboard")));
const AggregationPage = Loadable(lazy(() => import("views/admin/aggregation")));
const ProjectsPage = Loadable(lazy(() => import("views/admin/aggregation")));

const CreateAggregationPage = Loadable(
  lazy(() => import("views/admin/aggregation/create"))
);
const ViewAggregationPage = Loadable(
  lazy(() => import("views/admin/aggregation/view/index"))
);
const AggregationParametersPage = Loadable(
  lazy(() => import("views/admin/aggregation/view/add-parameters"))
);
const AddAggregationProjectsPage = Loadable(
  lazy(() => import("views/admin/aggregation/view/add-project"))
);
const ProjectDetailsPage = Loadable(
  lazy(() => import("views/admin/aggregation/view/projects"))
);
const SingleDevicePage = Loadable(
  lazy(() =>
    import(
      "views/admin/aggregation/view/projects/tabs/Cookstoves/SingleDevice/SingleDevicePage"
    )
  )
);

const PaymentsPage = Loadable(lazy(() => import("views/pay-go/payments")));
const CreatePaymentOption = Loadable(
  lazy(() => import("views/pay-go/payment-options/create"))
);
const ListPaymentOptions = Loadable(
  lazy(() => import("views/pay-go/payment-options/list"))
);
const ReportsPage = Loadable(lazy(() => import("views/pay-go/reports")));
const SettingsPage = Loadable(lazy(() => import("views/pay-go/settings")));
const EmissionAvoidancePage = Loadable(
  lazy(() => import("views/admin/aggregation/view/emission-avoidance"))
);

// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
  path: "/admin-portal",
  element: (
    <AuthGuard>
      <ProjectProvider>
        <AdminLayout />
      </ProjectProvider>
    </AuthGuard>
  ),
  children: [
    {
      path: "terms",
      element: (
        <RoleBasedGuard roles={["proponent", "admin"]} hasContent>
          <PayGoTerms />
        </RoleBasedGuard>
      ),
    },
    {
      path: "setup",
      element: <PayGoSetup />,
    },
    {
      path: "payment-integration",
      element: <PaymentIntegrations />,
    },
    {
      path: "dashboard",
      element: <DashboardPage />,
    },
    {
      path: "aggregations",
      element: <AggregationPage />,
    },
    {
      paths: "projects",
      element: <ProjectsPage />,
    },
    {
      path: "aggregations/create",
      element: <CreateAggregationPage />,
    },
    {
      path: "aggregations/view/:id",
      element: <ViewAggregationPage />,
    },
    {
      path: "aggregations/view/:id/parameters",
      element: <AggregationParametersPage />,
    },
    {
      path: "aggregations/view/:id/emission-avoidance",
      element: <EmissionAvoidancePage />,
    },
    {
      path: "aggregations/view/:id/add-projects",
      element: <AddAggregationProjectsPage />,
    },
    {
      path: "aggregations/view/:id/projects/:projectId",
      element: <ProjectDetailsPage />,
    },
    {
      path: "aggregations/view/:id/projects/:projectId/device/:deviceId",
      element: <SingleDevicePage />,
    },
    {
      path: "cookstoves",
      element: <DevicesPage />,
    },
    {
      path: "transactions",
      element: <TransactionsPage />,
    },
    {
      path: "payments",
      element: <PaymentsPage />,
    },
    {
      path: "reports",
      element: <ReportsPage />,
    },
    {
      path: "payment-options/create",
      element: <CreatePaymentOption />,
    },
    {
      path: "payment-options/list",
      element: <ListPaymentOptions />,
    },
    {
      path: "settings",
      element: <SettingsPage />,
    },
  ],
};

export default AdminRoutes;
